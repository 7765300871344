.top-bar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 30px;
    background-color: red;
    z-index: 777 !important;
}
.text-white {
    color: #fff;
}
.text-large {
    font-size: 1.5rem;
}
.playfair {
    font-family: "Playfair Display";
}

.googleCalendar{
    position: relative;
    min-height: 800px;
    height: 0;
    width: 90%;
    padding-bottom: 50%;
    margin: 2em auto 0 auto;
  }
  
  .googleCalendar iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }